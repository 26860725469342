import { Container, Navbar, NavbarBrand } from "reactstrap";

import './App.css';
import banner from './resources/FeedTheCraftBanner.webp';
import FTCButton from './components/FTCButton';

import { config } from '@fortawesome/fontawesome-svg-core'
import '@fortawesome/fontawesome-svg-core/styles.css'
import { faDiscord, faPatreon, faPaypal } from "@fortawesome/free-brands-svg-icons";
import { faServer } from "@fortawesome/pro-duotone-svg-icons";
import ServerTagline from "./components/ServerTagline";

import ftbStoneblockImage from './resources/ftb-icons_stoneblock3.webp';
import ragnamodImage from './resources/Ragnamod.webp';
import atm8Image from './resources/ATM8.webp';
import tnpImage from './resources/tnp6.webp';
import enigmatica6Image from './resources/e6.webp';

import cloudBreak from './resources/MinecraftBreakInTheClouds.webp'
import pyramid from './resources/MinecraftPyramid.webp';
import lakeHouse  from './resources/MinecraftLakehouse.webp'
import { useEffect, useState } from "react";

config.autoAddCss = false

function App() {
  const images = [lakeHouse, pyramid, cloudBreak];
  const [imageIndex, setImageIndex] = useState(0);
  const [resizeHandler, setResizeHandler] = useState(null);

  const discordClickHandler = () => window.open('https://discord.gg/feedthecraft');
  const serversClickHandler = () => jump('servers');
  const patreonClickHandler = () => window.open('https://www.patreon.com/feedthecraft');
  const paypalClickHandler = () => window.open('https://www.paypal.com/paypalme/feedthecraft');

  const setTopDivider  = () => {
    let topDivider = document.getElementById("top-divider");
    let mainNav = document.getElementById("main-nav");

    topDivider.style.top = '0px';

    setTimeout(() => {
      const targetHeight = mainNav.offsetHeight;
      topDivider.style.top = `${targetHeight}px`;
    }, 50);
  }

  const preloadImages = () => {
    let logo = new Image();
    logo.src = banner;
    images.forEach((image) => {
      const img = new Image();
      img.src = image;
    });
  }

  useEffect(() => {
    preloadImages();
    setTopDivider();

    const interval = setInterval(() => {
      setImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000);

    const handleResize = (e) => {
      e.preventDefault();
      setTopDivider();
    };
  
    setResizeHandler( window.addEventListener('resize', handleResize));

    return () =>  {
      clearInterval(interval);
      window.removeEventListener('resize', resizeHandler);
    }
  }, [images.length]);
  
  function jump(anchor) {
    let url = window.location.href;
    window.location.href = "#" + anchor;
    window.history.replaceState(null, null, url);
  }

  return (
    <>
      <Navbar id="main-nav" className='main-nav'>
          <NavbarBrand href="/">
            <img src={banner} alt="Feed the Beast Banner" />
          </NavbarBrand>
      </Navbar>      
      <div id="top-divider" className='top-divider'/>
      <div className='splash-wrapper'>
        <Container fluid className='splash'>
          <FTCButton text="Discord" icon={faDiscord} onClick={discordClickHandler} />
          <FTCButton text="Servers" icon={faServer} onClick={serversClickHandler} />
          <FTCButton text="Patreon" icon={faPatreon} onClick={patreonClickHandler}/>
          <FTCButton text="Paypal" icon={faPaypal} onClick={paypalClickHandler} />
        </Container>
      </div>
      <div className="divider-bottom"></div>
      <div id="servers" className="servers-wrapper">
         <div className="divider-top"></div>
        <h1>OUR SERVERS</h1>
        <ServerTagline image={ftbStoneblockImage} serverName="FTB: STONEBLOCK 3">
          <p>
            In a world of rock and stone, release your inner dwarf and build your subterranean kingdom!  
            Use Magic and Technology to forge your realm to your designs. Make your dimension your own, 
            or play with friends.
          </p>
        </ServerTagline>
        <ServerTagline image={ragnamodImage} serverName="Ragnamod VI: In the sky">
          <p>
            You can play with many different style of mods, tech, magic, Agriculture, adventure with 
            dungeons and new dimension like the undergarden, Sonicraft and Dragon !
          </p>
        </ServerTagline>
        <ServerTagline image={atm8Image} serverName="All The Mods 8 and All The Mods 8 (Donator server)">
          <p>
            All the Mods started out as a private pack for just a few friends of the dev that turned 
            into something others wanted to play! It has all the basics that most other “big name” packs 
            include but with a nice mix of some of newer or lesser-known mods as well. 
          </p>
          <p>
            We host a server for the public, and because of it’s popularity, have opened a second server
            for exclusive access to our FTC donators.
          </p>
        </ServerTagline>
        <ServerTagline image={tnpImage} serverName="TNP Limitless 6">
          <p>
          Explore a wild world, conquer dungeons, slay bosses, complete the quest book & much much more, jump in yourself to experience it all !   Now on 1.19.5!
          </p>
        </ServerTagline>
        <ServerTagline image={enigmatica6Image} serverName="Enigmatica 6">
          <p>
            Beautiful custom worldgen made with TerraForged. Tons of dungeons and structures
          </p>
        </ServerTagline>
      </div>
      <div className="divider-bottom-reverse"></div>
      <div className="divider-top-reverse down"></div>
      <div className="final-section" style={{ backgroundImage: `url(${images[imageIndex]})` }}>
        <div className="community-call-to-action">
          <div className="cta-title">JOIN THE COMMUNITY</div>
          <div className="cta-description">
            <p>
              Join us today and enjoy: building contests, multiple ranks, engaged mods and the all the best Minecraft can offer!
            </p>
          </div>
          <div className="cta-button-wrapper">
            <FTCButton text="Visit Our Discord"  classNames={["white"]} icon={faDiscord} style={{width: 340, border: 'none'}} onClick={discordClickHandler} />
          </div>
        </div>
      </div>
      <div className="divider-footer"></div>
      <div className="app-footer">
        <div className="footer-content">&copy; Feed the Craft 2023</div>
      </div>
    </>
  );
}

export default App;
