import React from 'react';

function ServerTagline(props) {
  return (
    <div className='server-tagline'>
      <img src={props.image} alt={props.alt ?? "Minecraft Server"} className='server-image'></img>
      <div className='server-details'>
        <div className='server-server-name'>{props.serverName}</div>
        <div className='server-description'>{props.children}</div>
      </div>
    </div>
  );
}

export default ServerTagline;