import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

function FTCButton(props) {
  let classNames = 'ftc-button'
  if (!!props.classNames) {
    classNames =  [classNames, ...props.classNames].join(' ');
  }

  return (
    <button onClick={props.onClick}
      className={classNames}
      style={{...props.style}}>
        <FontAwesomeIcon icon={props.icon} /> {props.text}
    </button>
  );
}

export default FTCButton;